<template>
  <div class="page" style="padding: 0.27rem;margin: 0rem;background:f7f7f7">
    <!-- 营业执照 -->
    <div class="certificationReg_img_box">
      <div class="box_top flex-r-c-c">
        <div class="img_name">营业执照认证</div>
      </div>
      <van-uploader v-model="fileList01" :after-read="afterRead01" :max-count="1">
        <van-image v-if="fileList01.length===0" width="6.29rem" height="3.68rem"
                   src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/certification01.png"
        />
      </van-uploader>
      <div v-if="fileList01.length === 0" class="up_tip">点击上传营业执照</div>
    </div>
    <div class="certificationReg_img_box" style="margin-top: 0.3rem;height: auto;">
      <van-form style="padding-top: 0.3rem;" @submit="onSubmit">
        <van-field v-model="certificationForm.companyName" label="公司名称" name="companyName" placeholder="请输入公司名称" />
        <!-- <van-field v-model="certificationForm.businessLicense" label="三证合一码" name="businessLicense"
                   placeholder="请输入三证合一码"
        /> -->
        <div class="login_sub" style="margin-left: 0.2rem;">
          <van-button round block type="info" native-type="submit" color="#0767ab" style="font-size: 0.43rem;"
                      class="next_btn position_fixed"
          >下一步，填写注册资料
          </van-button>
        </div>
      </van-form>
    </div>
  </div>
</template>

<script>
import './index.scss'
import my from '../../assets/js/lbc'
import { upProToOss } from '@/utils/ali-oss'
import { common_ocr } from '@/api/public'
import { enterpriseInformation } from '@/api/user'
import { GetOpenID } from '@/api/public'
export default {
  name: 'CertificationReg01',
  data() {
    return {
      step: 2,
      certificationShow: true,
      fileList01: [],
      loading: false,
      certificationForm: {},
      openId: ''

    }
  },
  beforeCreate() {
    // 微信公众号appid-开发-基本配置中获取
    const appId = my.appId; const toPath = my.host + '/#' + this.$route.path + '?mobile=' + this.$route.query.mobile
    // 核心步骤，获取code
    const hrefurl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
      appId + '&redirect_uri=' + encodeURIComponent(toPath) + '&response_type=code&scope=snsapi_base&state=#wechat_redirect'
    // 从地址栏获取code
    const code = my.getQueryString('code')
    if (code) {
      GetOpenID(code).then(res => {
        if (res.code === 20005) {
          this.$toast.clear(true); sessionStorage.setItem('jump', true); window.location.replace(hrefurl)
        } else {
          localStorage.setItem('openId', res.data)
          this.openId = res.data
        }
      }).catch(error => {
        if (error === 20005) {
        // this.$toast.clearAll(true)
        } else { this.$toast('请求失败！') }
      })
    } else {
      window.location.replace(hrefurl)
    }
  },
  created() { },
  methods: {
    // OCR识别
    OCR(url) {
      this.$toast.loading({ message: 'OCR识别中...', forbidClick: true, loadingType: 'spinner', duration: 0 })
      common_ocr(url).then(res => {
        var vetLicense = this.certificationForm.vetLicense || ''
        this.$set(res.data, 'vetLicense', vetLicense)
        if (res.data) { this.certificationForm = res.data }
        this.$toast.clear()
      }).catch(() => {
        this.$toast.clear()
      })
    },
    // 图片上传
    afterRead01(file) {
      file.status = 'uploading'
      file.message = '上传中...'
      upProToOss(file).then(res => {
        this.fileList01 = []
        this.fileList01.push({ url: res, isImage: true })
        this.$set(this.certificationForm, 'ocrUrl', res || '')
        this.OCR(res)
      })
    },
    // 点击
    onSubmit(values) {
      this.loading = true
      this.$set(this.certificationForm, 'inviteMobile', this.$route.query.mobile)
      console.log(this.certificationForm)
      enterpriseInformation(this.certificationForm).then(res => {
        // this.$toast({ message: '已提交审核！', duration: 3 * 1000 })
        this.$router.push('/linkPage/certificationReg02?ocrSignId=' + res.data.ocrSignId +
          '&ocrUrl=' + res.data.ocrUrl + '&businessLicense=' + res.data.businessLicense +
          '&companyName=' + res.data.companyName + '&legalPerson=' + res.data.legalPerson +
          '&openId=' + this.openId
        )
        this.loading = false
      })
    }
  }
}
</script>
